import styled from "styled-components";
import { useState } from "react";

import  FirstPartMobile  from  "../assets/FirstPartMobile.png"
import  SecondPartMobile from  "../assets/SecondPartMobile.png"
import  FirstPartDesktop  from  "../assets/FirstPartDesktopp.png"
import  SecondPartDesktop from  "../assets/SecondPartDesktop.png"
import  MainButtonImg from  "../assets/ButtonMain.png"
import  MainButtonHoverImg from "../assets/ButtonMainHover.png"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    min-height: 100vh;
    background-color: black;
    position: relative;
`

const WrapperDesktop = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    min-height: 100vh;
    background-color: black;
`

const FirstPartMobileComp = styled.img`
    width: 100vw;
    height: 100%;
`

const SecondPartMobileComp = styled.img`
    width: 100vw;
    height: 100%;
    margin-top: -0.2rem;
`

const FirstPartDesktopComp = styled.img`
    width: 100vw;
    height: 100%;
`
const SecondPartDesktopComp = styled.img`
    width: 100vw;
    height: 100%;
`


const MainButton = styled.img`
    position: absolute;
    top: 19.5%;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48rem;
`
const MainButtonDesktop = styled.img`
    position: absolute;
    top: 20%;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
`



const LinksWrapper = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -47rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
`
const LinksWrapperDesktop = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -20rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 93vw;
    height: 70rem;
`

const CustomLink = styled.div`
    width: 60rem;
    height: 33rem;
    margin-bottom: 1rem;
    background-color: transparent;
    cursor: pointer;
 `
const CustomLinkDesktop = styled.div`
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    flex: 1.5;
`
const CustomLinkDesktopSocial = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
`

const SocialMediaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 100%;

`

const MainPage = () => {
    const isMobile = window.innerWidth <= 720;
    const [isHovering, setIsHovering] = useState(false);


    if(isMobile){
        return (
            <Wrapper>
                <LinksWrapper>
                    <CustomLink onClick={() => window.location.href = 'https://t.me/glasspunks'}/>
                    <CustomLink onClick={() => window.location.href = 'https://x.com/glasspunks'}/>
                    <CustomLink onClick={() => window.location.href = 'https://t.me/glasspunks'}/>
                </LinksWrapper>
                <MainButton src={MainButtonImg}
                    onClick={() => window.location.href = 'https://t.me/glasspunks'}
                    alt="Main Button"
                />
                <FirstPartMobileComp src={FirstPartMobile}/>
                <SecondPartMobileComp src={SecondPartMobile}/>
            </Wrapper>
        )
    } else {
        return (
            <WrapperDesktop>
                <LinksWrapperDesktop>
                    <SocialMediaWrapper>
                        <CustomLinkDesktopSocial onClick={() => window.location.href = 'https://t.me/glasspunks'}/>
                        <CustomLinkDesktopSocial onClick={() => window.location.href = 'https://x.com/glasspunks'}/>
                    </SocialMediaWrapper>
                    <CustomLinkDesktop onClick={() => window.location.href = 'https://t.me/glasspunks'}/>
                </LinksWrapperDesktop>
                <MainButtonDesktop 
                    src={isHovering ? MainButtonHoverImg : MainButtonImg}
                    onClick={() => window.location.href = 'https://t.me/glasspunks'}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    alt="Main Button"
                />
                <FirstPartDesktopComp src={FirstPartDesktop}/>
                <SecondPartDesktopComp src={SecondPartDesktop}/>
            </WrapperDesktop>
        )
    }
}

export default MainPage;