import './App.css';

// comps
import MainPage from './components/MainPage';



function App() {
  return (
      <MainPage/>
  );
}

export default App;
